<template>
  <v-menu
    v-model="dateTimeMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateTime"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <v-tabs
      v-model="selectedTab"
      dark
      background-color="primary"
      class="mb-0 .rounded-b-0"
      centered
    >
      <v-tab>
        <v-icon>mdi-calendar</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>mdi-clock</v-icon>
      </v-tab>
      <v-tab-item>
        <v-date-picker
          v-model="date"
          class="rounded-t-0"
          @change="selectedTab = 1"
        ></v-date-picker> </v-tab-item
      ><v-tab-item>
        <v-time-picker
          v-model="time"
          class="rounded-t-0"
          ampm-in-title
          :use-seconds="show_second"
          @change="dateTimeMenu = false"
        ></v-time-picker>
      </v-tab-item>
    </v-tabs>
  </v-menu>
</template>

<script>
export default {
  props: {
    show_second: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "Set DateTime",
    },
    modelValue: {
      type: Date,
      default: () => new Date(),
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    dateTimeMenu: false,
    selectedTab: 0,
    date: "",
    time: "",
  }),
  computed: {
    dateTime: {
      get() {
        return this.date && this.time ? this.date + " " + this.time : "";
      },
    },
  },
  watch: {
    dateTimeMenu() {
      this.$emit("input", this.dateTime);
    },
  },
  created() {
    this.date = new Date(
      this.modelValue - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10);
    this.time = new Date(
      this.modelValue - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(11, this.show_second ? 19 : 16);
    // this.$emit("input", this.dateTime);
  },
};
</script>

<style>
</style>